import React from "react"
import { Grid, Paper } from "@material-ui/core";
import Spacer from "./Spacer";
import Fab from "@material-ui/core/Fab/Fab";

import form_icon from "./assets/img/form_icon.png";
import incontro_icon from "./assets/img/incontro_icon.png";
import secure_icon from "./assets/img/secure_icon.png";

import WOW from "wow.js";

const wow = new WOW();
wow.init();

export default (props) => (
    <div className="Slide Second" ref={props.refProp} >

        <Spacer h={"5vw"}/>

        <Paper elevation={24} className="step wow fadeInDown" data-wow-delay="0.4s">
            <Grid container className="section_1" spacing={24}>
              <Grid item md={2} sm={3} xs={12}>
                <img src={form_icon} alt="compila il form"/>
              </Grid>
              <Grid item md={10} sm={9} xs={12}>
                <h4><b>1.</b> Compila il form</h4>
                <p>Inviaci almeno una fotografia quanto più chiara ed esplicativa possibile dell'oggetto che
                    vorresti vendere, compila tutti i campi cercando di produrre una descrizione completa e
                    dettagliata.</p>
              </Grid>
            </Grid>
        </Paper>

        <Paper elevation={12} className="step wow fadeInDown" data-wow-delay="0.6s">
            <Grid container className="section_1" spacing={24}>
              <Grid item md={2} sm={3} xs={12}>
                <img src={incontro_icon} alt="incontra un agente"/>
              </Grid>
              <Grid item md={10} sm={9} xs={12}>
                <h4><b>2.</b> Incontra un nostro agente</h4>
                <p>Se il tuo oggetto sarà di nostro interesse per un possibile acquisto, sarai ricontattato entro 48
                    ore tramite telefono o email da un nostro agente per maggiori dettagli e per fissare un appuntamento.</p>
              </Grid>
            </Grid>
        </Paper>

        <Paper elevation={12} className="step wow fadeInDown" data-wow-delay="0.8s">
            <Grid container className="section_1" spacing={24}>
              <Grid item md={2} sm={3} xs={12}>
                <img src={secure_icon} alt="concludi la vendita"/>
              </Grid>
              <Grid item md={10} sm={9} xs={12}>
                <h4><b>3.</b> Completa la vendita in totale sicurezza</h4>
                <p>I nostri agenti ed Art-Advisor saranno a tua disposizione e, raggiunto l'accordo, concluderemo la
                    vendita firmando la dichiarazione di vendita necessaria al fine della conslusione della
                    transazione. Contestualmente riceverai il pagamento per l'intero importo concordato.</p>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>

              </Grid>
            </Grid>
        </Paper>

        <Spacer h={"2vw"}/>

        <div style={{textAlign: "center"}} className="wow zoomIn" data-wow-delay="0.8s">
          <Fab variant="extended" color="secondary" aria-label="Add" onClick={props.toggleDrawer('richiesta', true)} >
              Compila subito il form di richiesta
          </Fab>
        </div>

        <Spacer h={"5vw"}/>

    </div>
)
