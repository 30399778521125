import React, { Component } from 'react';
import { Events, animateScroll, scrollSpy } from "react-scroll"
import './App.css';
import FirstSlide from "./FirstSlide"
import SecondSlide from "./SecondSlide"
import ThirdSlide from "./ThirdSlide"
import ForthSlide from "./ForthSlide"
import FifthSlide from "./FifthSlide"
import SixthSlide from "./SixthSlide"
import Footer from "./Footer"
import Carousel from "./Carousel"

import Richiesta from "./Richiesta"
import Contatti from "./Contatti"

import Drawer from '@material-ui/core/Drawer';

import Cancel from "@material-ui/icons/Cancel"
import ArrowUpward from "@material-ui/icons/ArrowUpward";

import Slide from '@material-ui/core/Slide';

import "animate.css/animate.min.css";

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            richiesta: false,
            contatti: false,
            toTop: false
        };
        this.secondSlide = React.createRef();
        this.thirdSlide = React.createRef();
        this.forthSlide = React.createRef();
        this.fifthSlide = React.createRef();
        this.sixthSlide = React.createRef();

        this.scrollTo = this.scrollTo.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
    }

    toggleDrawer = (drawer, open) => () => {
        if(open){
            document.querySelector('body').style.width = '100vw'
            document.querySelector('body').style.height = '100vh'
            document.querySelector('body').style.position = 'fixed'
        }else{
            setTimeout(function(){
                document.querySelector('body').style.position = 'static'
            },600)
        }
        this.setState({
            [drawer]: open,
        });
    };

    scrollTo = (ref) => () =>{
        window.removeEventListener('scroll', this.handleScroll);
        const scrollTo = ref ? this[ref].current.offsetTop : 0
        animateScroll.scrollTo(scrollTo)
    }

    componentDidMount () {
        const $this = this;
        window.addEventListener('scroll', $this.handleScroll);
        Events.scrollEvent.register('end', () => {
            window.addEventListener('scroll', $this.handleScroll);
        });
        scrollSpy.update()
    }

    handleScroll () {
        this.setState({toTop: window.scrollY > 600})
    }

    render() {
    return (
      <div className="App">
          <FirstSlide toggleDrawer={this.toggleDrawer} scrollTo={this.scrollTo} />
          <SecondSlide toggleDrawer={this.toggleDrawer} refProp={this.secondSlide} />
          { /*<ThirdSlide refProp={this.thirdSlide}/> */ }
          <ForthSlide refProp={this.forthSlide}/>
          <Carousel/>
          <FifthSlide refProp={this.fifthSlide}/>
          {/*<SixthSlide refProp={this.sixthSlide}/>*/}
          <Footer/>

          <Drawer
              anchor="bottom"
              open={this.state.richiesta}
              onClose={this.toggleDrawer('richiesta', false)}
          >
              <Cancel className="cancel" color={"primary"} onClick={this.toggleDrawer('richiesta', false)}/>
              <Richiesta handleClose={this.toggleDrawer('richiesta', false)}/>
          </Drawer>

          <Drawer
              anchor="bottom"
              open={this.state.contatti}
              onClose={this.toggleDrawer('contatti', false)}
          >
            <Contatti/>
          </Drawer>

          <Slide direction="left" in={this.state.toTop} mountOnEnter unmountOnExit>
              <ArrowUpward className={"ToTop"} onClick={this.scrollTo(0)}/>
          </Slide>
      </div>
    );
  }
}

export default App;
