import React from "react"
import { Grid, Paper } from "@material-ui/core";
import Spacer from "./Spacer";
import WOW from "wow.js";

const wow = new WOW();
wow.init();

export default (props) => (
    <div className="Slide h-auto" ref={props.refProp} style={{background: "#59a9f2"}}>
        <Grid container className="section_1" spacing={24}>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
                <div className="content_wrapper text-white">
                    <h2 className="text-center">Servizi offerti</h2>
                    <p className={"mb-0"}>Il nostro campo di interesse spazia dai dipinti all'oggettistica varia, avori, coralli,
                        argenteria, Rolex ed altri orologi di valore, argenti antichi, mobili, cristalleria (Saint
                        Louis, Baccarat, Lalique).<br/>
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Paper elevation={24} style={{padding: "1px 30px"}} className="wow fadeIn">
                    <ul>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Acquisto di oggetti d'arte o di preziosi antichi e moderni.</li>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Acquisto di rimanenze di magazzino di negozi d'arte o di lusso</li>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Acquisto di oggetti in argento di raffinata manifattura siano antichi o moderni</li>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Acquisto di opere d'arte moderna provviste di adeguata ed autorevole certificazione di autenticità</li>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Ottimizzazione della vendita di oggetti d'arte e preziosi mediante adeguato inserimento in apposita asta (nazionale o internazionale) specializzata</li>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Libri antichi di pregio (ad esclusione di settore giuridico o medico antecedenti il XVII secolo)</li>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Libri d'arte antichi e moderni</li>
                      <li className="wow fadeInDown" data-wow-delay="0.5s">Art-Advisor</li>
                      </ul>
                    </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
                <div className="example" style={{ padding: "30px", borderColor: "#f50057"}}>
                    <i>Oggetti che invece <b>non</b> sono di nostro interesse:<br/>
                        <br/>
                        Oggetti d'oro senza valore aggiunto (destinati alla fusione).<br/>
                        Oggetti dei quali non si abbia la certezza della provenienza.<br/>
                        Oggetti di interesse archeologico o tutelati da vincoli dello Stato.<br/>
                        Oggetti di cui non si può disporre legittimamente ed esclusivamente.<br/>
                      Tutto ciò che non riteniamo opportuno sulla base della valutazione dei nostri esperti.</i>
                </div>
            </Grid>
        </Grid>
        <Spacer h={"5vw"}/>
    </div>
)
