import React from "react"
import Logo from "./assets/img/logo.png";
import Grid from "@material-ui/core/Grid/Grid";
import Fab from "@material-ui/core/Fab/Fab";
import Omino1 from "./assets/img/omino_1.png";
import WOW from "wow.js";
import Spacer from "./Spacer";

import Menu from "./Menu";

const wow = new WOW();
wow.init();

export default (props) => (
    <div className="Slide First">
        <div className="Logo">
            <img src={Logo} alt="Compro-Arte.it"/>
            <Menu {...props} />
        </div>
        <Grid container className="section_1" spacing={24}>
            <Grid item md={6} sm={6} xs={12}>
                <div className="content_wrapper" style={{maxWidth: "40vw"}}>
                    <h1 className="wow fadeInDown" data-wow-delay="0.5s">Vuoi vendere<br/>un oggetto di valore?</h1>
                    <p className="wow fadeInDown" data-wow-delay="0.5s">Compro Arte è il primo servizio in Italia a valutare grauitamente i tuoi
                        oggetti di valore, ti invieremo una valutazione ufficiale effettuata da un
                        esperto e, se troveremo un accordo, potrai concludere la vendita
                        dirattamente con un nostro agente!</p>
                      <div className="wow fadeInDown" data-wow-delay="0.5s">
                        <Fab variant="extended" color="primary" aria-label="Add" onClick={props.scrollTo('secondSlide')} >
                            Come funziona
                        </Fab>
                        <Fab variant="extended" color="secondary" aria-label="Add" onClick={props.toggleDrawer('richiesta', true)} >
                            Richiedi una valutazione
                        </Fab>
                      </div>
                </div>
            </Grid>
            <Grid item md={6} sm={6} xs={12} style={{textAlign: "center"}}>
                <img src={Omino1} alt="Vuoi vendere un oggetto di valore?" className="Omino1 wow fadeIn" data-wow-delay="0.8s"/>
            </Grid>
        </Grid>
        <Spacer h={"5vw"}/>
    </div>
)
