import React from "react"
import Spacer from "./Spacer";

import coin from "./assets/img/slider/coin.png";
import crowns from "./assets/img/slider/crowns.png";
import diamond from "./assets/img/slider/diamond.png";
import statue from "./assets/img/slider/statue.png";
import painting from "./assets/img/slider/painting.png";
import ring from "./assets/img/slider/ring.png";
import sculpture from "./assets/img/slider/sculpture.png";
import watches from "./assets/img/slider/watches.png";

const t = [1, 2, 3, 4, 5];

export default () => (
    <div className="Slide h-auto full-width">
        <Spacer h={"4vw"}/>
        <div className={"intermezzo"}>
            <div className={"intermezzo_body"}>
                {t.map((t) => (
                    <div key={t} className={"slider"}>
                        <img src={coin} className={"slidingImg"}/>
                        <img src={crowns} className={"slidingImg"}/>
                        <img src={diamond} className={"slidingImg"}/>
                        <img src={statue} className={"slidingImg"}/>
                        <img src={painting} className={"slidingImg"}/>
                        <img src={ring} className={"slidingImg"}/>
                        <img src={sculpture} className={"slidingImg"}/>
                        <img src={watches} className={"slidingImg"}/>
                    </div>
                ))
                }}
            </div>
        </div>
        <Spacer h={"4vw"}/>
    </div>
)