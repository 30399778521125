import React from "react"
import Grid from "@material-ui/core/Grid/Grid";
import Omino5 from "./assets/img/omino_5.png";
import Fab from "@material-ui/core/Fab/Fab";

export default () => (
    <div className="Slide h-auto text-white" style={{background: "#2a2b86"}}>
        <Grid container className="section_1" spacing={24}>
            <Grid item xs={12} className={"text-center"}>
                <div className="content_wrapper">
                    <p>® Compro-Arte.it 2019</p>
                </div>
            </Grid>
        </Grid>
    </div>
)
