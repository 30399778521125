import React from "react"
import {
    TextField, Checkbox,
    FormControlLabel,
    MenuItem,
    Fab,
    Grid, Button, CircularProgress
} from "@material-ui/core"

import Info from "@material-ui/icons/Info";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Privacy from "./Privacy";
import placeholder from "./assets/img/picture.png";

const Items = ["Orologio", "Mobile", "Oggetto d'arte", "Cristalleria francese", "Numismatica", "Collezioni", "Libri", "Pietre prezione", "Dipinto", "Scultura", "Gioielli", "Argenteria", "Altro"];


class Richiesta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: "",
            description: "",
            name: "",
            surname: "",
            email: "",
            city: "",
            phone: "",
            privacy: false,

            images: [],

            privacyDialog: false,
            step1: "active",
            step2: "todo",
            step3: "todo",

            response: false,
            error: '',

            loading: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeCB = this.handleChangeCB.bind(this);
        this.handleClosePrivacy = this.handleClosePrivacy.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.openPrivacyDialog = this.openPrivacyDialog.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    componentDidMount() {
    }

    handleSubmit() {

        this.setState({
            loading: true
        })
        let data = new FormData();
        data.append('type', this.state.item);
        data.append('name', this.state.name);
        data.append('surname', this.state.surname);
        data.append('description', this.state.description);
        data.append('email', this.state.email);
        data.append('telefono', this.state.phone);
        data.append('city', this.state.city);

        for (let i = 0; i < this.state.images.length; i++){
            data.append('file' + i, this.state.images[i]);
        }

        const $this = this;

        fetch('https://www.galleria-ammendolea.com/wp-json/emailapi/v1/submit-form', {
            method: "POST",

            body: data
            // body: JSON.stringify({
            //     type: this.state.item,
            //     name: this.state.name,
            //     surname: this.state.surname,
            //     description: this.state.description,
            //     email: this.state.email,
            //     telefono: this.state.phone,
            //     city: this.state.city
            // })
        })
            .then(res => {
                console.log(res)
                if(res.ok){
                    res.json().then(res => {
                        $this.setState({
                            response: res.email_status,
                            loading: false
                        })
                    })
                }else{
                    $this.setState({
                        error: 'Error: ' + res.status,
                        loading: false
                    })
                }

                this.setState({
                    step3: "done"
                })
                setTimeout(function(){
                    $this.props.handleClose()
                }, 2000)
            })
    }

    handleImageUpload(event) {
        console.log( event.target.files, URL.createObjectURL(event.target.files[0]))
        const newImages = Array.from(this.state.images);

        for (let i = 0; i < event.target.files.length; i++){
            newImages.push( event.target.files[i] )
        }
        console.log(newImages);

        this.setState({
            images: newImages
        })
        const $this = this;
        setTimeout(function(){
            console.log($this.state);
        }, 100)
    }

    removeImage(img){
        const newImages = Array.from(this.state.images);
        newImages.splice(this.state.images.indexOf(img), 1)
        document.getElementById("raised-button-file").value = null
        this.setState({
           images: newImages
        })
    }

    openPrivacyDialog(){
        this.setState({
            privacyDialog: true
        })
    }

    handleClosePrivacy() {
        this.setState({
            privacyDialog: false
        })
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleChangeCB(event) {
        this.setState({
            [event.target.name]: event.target.checked
        })
    }

    goTo(from, to) {
        this.setState({
            [from]: "done",
            [to]: "active"
        })
    }

    render() {
        return (
            <div className="form">
                <div className="steps">
                    <div className={"step " + this.state.step1}>
                        1
                    </div>
                    <div className={"step " + this.state.step2}>
                        2
                    </div>
                    <div className={"step " + this.state.step3}>
                        3
                    </div>
                </div>
                {
                    this.state.step1 === "active" &&
                    (
                        <div className="step_1">
                            <h3>Che ci proponi oggi?</h3>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                this.goTo('step1', 'step2')
                            }}>
                                <TextField
                                    select
                                    label="Cosa vendi?"
                                    value={this.state.item}
                                    helperText="Seleziona dalla lista l'oggetto che vuoi vendere"
                                    margin="normal"
                                    name="item"
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    fullWidth
                                    required
                                >
                                    {Items.map(option => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id="outlined-multiline-static"
                                    label="Descrizione"
                                    name="description"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                    helperText="Cerca di essere il più dettagliato possibile, più informazioni ci dai meglio potremo valutare il tuo pezzo."
                                    multiline
                                    rows="5"
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    required
                                />
                                <Fab variant="extended" color="secondary" aria-label="Add" type={"submit"}
                                     style={{float: "right"}}>
                                    Avanti
                                </Fab>
                            </form>
                        </div>
                    )
                }
                {
                    this.state.step2 === "active" &&
                    (
                        <div className="step_2">
                            <h3>I tuoi dati</h3>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                this.goTo('step2', 'step3')
                            }}>
                                <Grid container spacing={24}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Nome"
                                            name="name"
                                            autoComplete="name"
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.name}
                                            onChange={this.handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Cognome"
                                            name="surname"
                                            autoComplete="surname"
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.surname}
                                            onChange={this.handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Email"
                                            name="email"
                                            autoComplete="email"
                                            type="email"
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Telefono"
                                            name="phone"
                                            autoComplete="phone"
                                            type="phone"
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                    <Grid container spacing={24}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Città"
                                            name="city"
                                            autoComplete="city"
                                            margin="normal"
                                            variant="outlined"
                                            value={this.state.city}
                                            onChange={this.handleChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{display: "flex", alignItems: "center"}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.privacy}
                                                    name="privacy"
                                                    onChange={this.handleChangeCB}
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <div>
                                                    <p>Dichiaro di accettare i termini di utilizzo e trattamento della privacy</p>
                                                </div>
                                            }
                                        />
                                        <Info onClick={this.openPrivacyDialog} />
                                    </Grid>
                                    <Privacy open={this.state.privacyDialog} handleClose={this.handleClosePrivacy}/>
                                </Grid>

                                <Fab variant="extended" color="primary" aria-label="Add"
                                     onClick={() => (this.goTo('step2', 'step1'))} style={{float: "left"}}>
                                    Indietro
                                </Fab>
                                <Fab variant="extended" color="secondary" aria-label="Add" type={"submit"}
                                     style={{float: "right"}}>
                                    Avanti
                                </Fab>
                            </form>
                        </div>
                    )
                }
                {
                    this.state.step3 === "active" &&
                    (
                        <div className="step_3">
                            {
                                this.state.loading ?
                                    (
                                        <div>
                                            <h3>Invio in corso...</h3>
                                            <div style={{textAlign: "center"}}>
                                                <CircularProgress/>
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        <div>
                                            <h3>Aggiungi qualche foto</h3>
                                            <div className="images">
                                                <label htmlFor="raised-button-file" className={"upload_button"}>
                                                    <PhotoCamera />
                                                </label>
                                                {this.state.images.length ?

                                                    this.state.images.map((img, i) => (
                                                        <div className="image_wrapper" key={img.name + i}>
                                                            <DeleteForever onClick={() => this.removeImage(img)} />
                                                            <img src={URL.createObjectURL(img)} className="image_preview"  />
                                                        </div>

                                                    )) : (
                                                        <div>
                                                            <img src={placeholder} className="image_preview" />
                                                        </div>


                                                    )}
                                            </div>
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                this.handleSubmit('step_3')
                                            }}>
                                                <input
                                                    accept="image/*"
                                                    id="raised-button-file"
                                                    multiple
                                                    type="file"
                                                    onChange={this.handleImageUpload}
                                                    style={{display: "none"}}
                                                    ref={"image_input"}
                                                />
                                                <Fab variant="extended" color="primary" aria-label="Add"
                                                     onClick={() => (this.goTo('step3', 'step2'))} style={{float: "left"}} disabled={this.state.loading}>
                                                    Indietro
                                                </Fab>
                                                <Fab variant="extended" color="secondary" aria-label="Add" type={"submit"}
                                                     style={{float: "right"}} disabled={this.state.loading}>
                                                    Avanti
                                                </Fab>
                                            </form>
                                        </div>
                                    )
                            }

                        </div>
                    )
                }
                {
                    this.state.response &&
                    (
                        <div className="step_3">
                            <div>
                                <h3>Tutto fatto!!</h3>
                                <div style={{textAlign: "center"}}>
                                    La tua email è stata inviata correttamente, sarai contattato al più presto da un nostro operatore!
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    this.state.error &&
                    (
                        <div className="step_3">
                            <div>
                                <h3>Oops..</h3>
                                <div style={{textAlign: "center"}}>
                                    Qualcosa è andato storto con la tua email, abbiamo riscontrato un errore durante l'invio, ti preghiamo di riprovare più tardi...
                                    <small>{this.state.error}</small>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

export default Richiesta;