import React from "react"
import Grid from "@material-ui/core/Grid/Grid";
import Omino5 from "./assets/img/omino_5.png";
import Spacer from "./Spacer";

export default (props) => (
    <div className="Slide Forth h-auto" ref={props.refProp}>
        <Spacer h={"5vw"}/>
        <h2 className="text-center colored">Tanti vantaggi<br/>in un servizio unico!</h2>
        <Grid container className="section_1" spacing={24}>
            <Grid item xs={12} sm={8}>
                <div className="content_wrapper">
                    <p>Per te abbiamo riservato tantissimi vantaggi esclusivi per rendere ogni momento della tua
                        operazione di vendita senza stress e soddisfacente.<br/>
                        <br/>
                        Avere valutato il proprio oggetto, in oro o argento, con la manifattura e non solo il peso.<br/>
                        Avere il consiglio dei nostri Art-Advisor su come ottenere il massimo dalle vostre vendite.<br/>
                        Ottenere il pagamento contestualmente alla vendita, superando le attese tipiche delle case
                        d'asta, che possono andare dai 120 ai 180 giorni, che, inoltre, applica una commissione che si
                        attesta intorno al 40% sul valore della vendita.<br/>
                      <br /></p>
                </div>
            </Grid>
            <Grid item xs={2} sm={4} className={"text-right"}>
                <img src={Omino5} alt="Vuoi vendere un oggetto di valore?" className="Omino5"/>
            </Grid>
            <Grid item xs={12} sm={12} className="text-left example">
              <i>
                Es.: su una vendita di 10.000€ effettuata tramite una casa d'aste il venditore percepirà circa
                6000€ netti, poichè al ricavo della vendita lordo si sottraggono:<br/>
                12% commissione acquirente<br/>
                24% commissione venditore<br/>
                spese di assicurazione, trasporto ed eventuale deposito
              </i>
            </Grid>

        </Grid>
        <Spacer h={"10vw"}/>
    </div>
)
