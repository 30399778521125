import React, { Component } from "react"
import Slide from '@material-ui/core/Slide';

class Menu extends Component {

    constructor(props){
        super(props)
        this.state = {
            menuOpen: false
        }

        this.handleMenu = this.handleMenu.bind(this)
    }

    handleMenu(){
        this.setState({menuOpen: !this.state.menuOpen})
    }
    render(){
        const props = this.props
        return(
            <div className={"menuWrapper"}>
                <div className={["menuIcon", this.state.menuOpen && "menuOpen"].join(" ")} onClick={this.handleMenu}>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </div>
                <div className={"menuItems"}>
                    <Slide direction="down" in={this.state.menuOpen} timeout={400} mountOnEnter unmountOnExit>
                        <span onClick={props.scrollTo('secondSlide')} >Come funziona</span>
                    </Slide>
                    <Slide direction="down" in={this.state.menuOpen} timeout={400} mountOnEnter unmountOnExit>
                        <span onClick={props.scrollTo('forthSlide')} >Vantaggi per te</span>
                    </Slide>
                    <Slide direction="down" in={this.state.menuOpen} timeout={500} mountOnEnter unmountOnExit>
                        <span onClick={props.scrollTo('fifthSlide')} >Servizi offerti</span>
                    </Slide>
                </div>
            </div>
        )
    }
}

export default Menu;
