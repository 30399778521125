import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default (props) => (
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        scroll={"body"}
        aria-labelledby="scroll-dialog-title"
    >
        <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <b>Modalità di gestione del sito in riferimento al trattamento dei dati personali.</b><br/>

                Informativa resa anche ai sensi dell'art. 13 del d.lgs. n. 196/2003 - Codice in materia di protezione
                dei dati personali - a coloro che interagiscono con i servizi web, accessibili per via telematica a
                partire dall’indirizzo: www.compro-arte.com.<br/>

                <b>"TITOLARE" DEL TRATTAMENTO</b><br/>

                "Titolare" del trattamento dei dati personali eventualmente trattati a seguito dell’utilizzo del sito
                internet www.compro-arte.com, e di ogni altro dato utilizzato per la fornitura dei nostri servizi legati
                al Web, il Marketing e la pubblicità tutta, è il Legale Rappresentante pro-tempore della Nonsolooro
                s.r.l che ha sede in Messina in via Camiciotti, 37.<br/>

                <b>FINALITA' DEL TRATTAMENTO DEI DATI</b><br/>
                Nessun dato derivante dai servizi web viene comunicato o diffuso a strutture esterne o soggetti esterni,
                fatto salvo il caso di occasionali operazioni di manutenzione e salvo che la comunicazione sia imposta
                da obblighi di legge o sia strettamente necessaria per l’adempimento delle richieste dell’utente.

                <b>DIRITTI DEGLI INTERESSATI</b><br/>
                I soggetti cui si riferiscono i dati personali, ai sensi dell'art. 7 del D.Lgs. n.196/2003, hanno il
                diritto in qualunque momento di ottenere la conferma dell'esistenza o meno dei medesimi dati e di
                conoscerne il contenuto e l'origine, verificarne l'esattezza o chiedere di integrarli, aggiornarli,
                oppure rettificarli. I soggetti cui si riferiscono i dati personali hanno, inoltre, il diritto di
                chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in
                violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento. Le
                richieste relative all'art. 7 del D.Lgs. n.196/2003 devono essere rivolte al Legale Rappresentante
                pro-tempore della Nonsolooro s.r.l., che ha sede in Messina in via Camiciotti, 37, oppure direttamente
                all’indirizzo e-mail: info@compro-arte.com.<br/>


                <b>TRATTAMENTO DEI DATI</b><br/>

                $1· I dati relativi alla navigazione vengono utilizzati al solo fine di ricavare informazioni
                statistiche anonime sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati
                immediatamente dopo l’elaborazione. I dati potrebbero essere utilizzati per l’accertamento di
                responsabilità in caso di ipotetici reati informatici ai danni del sito: salva questa eventualità, allo
                stato i dati sui contatti web non persistono per più di sette giorni.<br/>

                <b>$1· Dati forniti volontariamente dall' utente</b><br/>
                L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questo sito,
                nonché la compilazione di eventuali moduli, comporta l’acquisizione dell’indirizzo del mittente,
                necessario per rispondere alle richieste, nonché degli eventuali altri dati personali inseriti nella
                missiva.

                <b>$1· Cookies</b><br/>
                Nessun dato personale degli utenti viene in proposito acquisito dal sito. Non viene fatto uso di cookies
                per la trasmissione di informazioni di carattere personale, né vengono utilizzati c.d. cookies
                persistenti di alcun tipo, ovvero sistemi per il tracciamento degli utenti. L’uso di c.d. cookies di
                sessione (che non vengono memorizzati in modo persistente sul computer dell’utente e svaniscono con la
                chiusura del browser) è strettamente limitato alla trasmissione di identificativi di sessione
                (costituiti da numeri casuali generati dal server) necessari per consentire l’esplorazione sicura ed
                efficiente del sito. I cookies utilizzati in questo sito evitano il ricorso ad altre tecniche
                informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non
                consentono l’acquisizione di dati personali identificativi dell’utente.<br/>
                <br/>

                Per migliorare il servizio offerto è gradita una immediata segnalazione di malfunzionamenti, abusi o
                suggerimenti all’indirizzo di posta elettronica: info@compro-arte.com.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose} color="primary">
                Ok
            </Button>
        </DialogActions>
    </Dialog>
)